<template>
    <form class="multisteps_form">
        <div class="multisteps_form_panel" style="display: block;">
       <div class="step_content d-flex justify-content-between pt-5 pb-2">
          <h4>Patient Details</h4>
          <span class="text-end text-uppercase">question 1 to 3</span>
       </div>
       <div class="step_progress_bar">
          <div class="progress rounded-pill">
             <div class="progress-bar" style="width:24%"></div>
          </div>
       </div>
       <div class="form_content">
          <div class="question_title py-5">
             <h1 class="text-capitalize"></h1>
          </div>

          <div class="alert alert-danger" v-if="errors.error" role="alert">
              {{errors.error}}
          </div>
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 form_items pb-2">
             <div class="col">
                First Name
                <input v-model="formData.firstName" type="text" class="form-control" id="firstName" placeholder="First Name"  :disabled="loadingProcess">
                <span class="text-danger" v-if="errors.firstName">
                  {{ errors.firstName}}
                </span>
             </div>
             <div class="col">
                Last Name
                <input v-model="formData.lastName" type="text" class="form-control" id="lastName" placeholder="Last Name"  :disabled="loadingProcess">
                <span class="text-danger" v-if="errors.lastName">
                  {{ errors.lastName}}
                </span>
             </div>
          </div>
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 form_items pb-2">
             <div class="col">
                Email
                <input v-model="formData.email" type="email" class="form-control" id="email" placeholder="Email"  :disabled="loadingProcess">
                <span class="text-danger" v-if="errors.email">
                  {{ errors.email }}
                </span>
             </div>
             <div class="col">
                Mobile Number
                <input v-model="formData.mobileNumber" type="text" class="form-control" id="mobileNumber" placeholder="Mobile Number" :disabled="loadingProcess">
                <span class="text-danger" v-if="errors.mobileNumber">
                  {{ errors.mobileNumber}}
                </span>
             </div>
          </div>
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 form_items pb-2">
              <div class="col">
                  Date of Birth
                  <input v-model="formData.dateOfBirth" type="date" class="form-control" id="dateOfBirth" placeholder="Date of Birth" :disabled="loadingProcess">
                  <span class="text-danger" v-if="errors.dateOfBirth">
                    {{ errors.dateOfBirth}}
                  </span>
              </div>
              <div class="col">
                  <div class="">Gender</div>
                  <input type="radio" id="male" value="male" v-model="formData.gender" :disabled="loadingProcess">
                  <label class="patient-gender-lable" for="male">Male</label>
                  <input type="radio" id="female" value="female" v-model="formData.gender" :disabled="loadingProcess">
                  <label class="patient-gender-lable" for="female">Female</label>
                  <br>
                  <span class="text-danger" v-if="errors.gender">
                    {{ errors.gender}}
                  </span>
              </div>
          </div>
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 form_items pb-2">
              <div class="col">
                  Address
                  <input v-model="formData.address" type="text" class="form-control" id="address" placeholder="Address" :disabled="loadingProcess">
                  <span class="text-danger" v-if="errors.address">
                    {{ errors.address}}
                  </span>
              </div>
             <div class="col">
                Zip Code
                <input v-model="formData.zipCode" type="text" class="form-control" id="zipCode" placeholder="Zip Code" @focusout="validateZipcode()" :disabled="loadingProcess">
                <span class="text-danger" v-if="errors.zipCode">
                  {{ errors.zipCode}}
                </span>
             </div>
          </div>
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 form_items pb-2">
             <div class="col">
                State
                <input v-model="formData.state" type="text" class="form-control" id="state" placeholder="State" :disabled="formData.zipCode? true:false">
                <span class="text-danger" v-if="errors.state">
                  {{ errors.state }}
                </span>
             </div>
             <div class="col">
                City
                <input v-model="formData.city" type="text" class="form-control" id="city" placeholder="City" :disabled="formData.zipCode? true:false">
                <span class="text-danger" v-if="errors.city">
                  {{ errors.city }}
                </span>
             </div>
          </div>
       </div>
    </div>
        <div class="form_btn pt-5 justify-content-between">
            <button style="display: none;" type="button" class="prev_btn text-uppercase bg-white" id="prevBtn" @click="nextPrev(-1)"><span><i class="fas fa-arrow-left"></i></span> Back </button>
            <button type="button" class="next_btn text-uppercase text-white float-r" :disabled="loadingProcess" id="nextBtn" @click="validateAddress()">{{nextButton}} <span><i :class="loadingProcess?'spinner-border spinner-border-sm':'fas fa-arrow-right'"></i></span></button>
        </div>
    </form>
</template>

<script>
const axios = require('axios');
export default {
  name: 'PatientDetails',
  props: [
      'forwardData',
  ],
  created(){
      let patient_details = JSON.parse(localStorage.getItem("patient_details"));
      if (patient_details) {
          this.formData = patient_details.form_data;
      }
  },
  mounted(){
  },
  data() {
    return {
        formData: {
            firstName:    '',
            lastName:     '',
            email:        '',
            mobileNumber: '',
            dateOfBirth:  '',
            gender:       '',
            address:      '',
            zipCode:      '',
            state:        '',
            city:         ''
        },
        errors: [],
        loadingProcess:false,
        nextButton:    'Next',
        requestInProgress: false

    }
  },
  computed: {
  },
  methods: {
    nextPrev(n,forwardData){
        this.$parent.currentStep(n,forwardData);

        if (n == 1) {
            localStorage.setItem("patient_details", JSON.stringify({'form_data':this.formData}));
        }
    },

    addPatient() {
        let vm = this;
        vm.validateZipcode();
        vm.loadingProcess = true;
        vm.nextButton = 'Processing';
        axios({method: 'POST', data: 'action=addPatient&PatientDetails='+JSON.stringify(vm.formData), url: window.location.origin+'/functions.php',}).then(function (response) {
                if (response.data.status === 'success') {
                    vm.nextPrev(1,response.data);
                }else {
                    if (response.data.reason) {
                        vm.$set(vm.errors, 'error', response.data.reason.email[0]);
                    }else {
                        vm.$set(vm.errors, 'error', response.data.message);
                    }
                    vm.loadingProcess = false;
                    vm.nextButton = 'Next';
                }
              });
    },

    isValidData () {
        this.errors = [];
        if (!this.formData.firstName) {
            this.$set(this.errors, 'firstName', 'First Name is required.');
        }
        if (!this.formData.lastName) {
            this.$set(this.errors, 'lastName', 'Last Name is required.');
        }
        if (!this.formData.email) {
            this.$set(this.errors, 'email', 'Email is required.');
        }
        if (!this.formData.mobileNumber) {
            this.$set(this.errors, 'mobileNumber', 'Mobile Number is required.');
        }
        if (!this.formData.dateOfBirth) {
            this.$set(this.errors, 'dateOfBirth', 'Date Of Birth is required.');
        }
        if (!this.formData.gender) {
            this.$set(this.errors, 'gender', 'Gender is required.');
        }
        if (!this.formData.address) {
            this.$set(this.errors, 'address', 'Address is required.');
        }
        if (!this.formData.zipCode) {
            this.$set(this.errors, 'zipCode', 'Zip Code is required.');
        }
        if (!this.formData.state) {
            this.$set(this.errors, 'state', 'State is required.');
        }
        if (!this.formData.city) {
            this.$set(this.errors, 'city', 'City is required.');
        }
    },

    validateZipcode() {
        let vm = this;
        axios({method: 'POST', data: 'action=getStateAndCity&code='+vm.formData.zipCode, url: window.location.origin+'/functions.php',}).then(function (response) {
            let data = response.data;
            if (data.status == 'success') {
                if (vm.errors.zipCode) {vm.errors.zipCode = [];}
                vm.formData.state = data.state;
                vm.formData.city  = data.city;
            }else {
                vm.formData.state = '';
                vm.formData.city  = '';
                vm.$set(vm.errors, 'zipCode', data.message);
            }
        });
    },
    validateAddress() {
        let vm = this;
        vm.isValidData();
        if (Object.keys(vm.errors).length) {
            return;
        }else {
            if (vm.formData.address) {
            vm.loadingProcess = true;
            axios({method: 'POST', data: 'action=validateAddress&state=' + vm.formData.state + '&address=' + vm.formData.address + '&city=' + vm.formData.city, url: window.location.origin+'/functions.php',}).then(function (response) {
                let data = response.data;
                if (data.status == 'error') {
                    vm.$set(vm.errors, 'address', data.message);
                    vm.loadingProcess = false;
                }else {
                    if (vm.errors.address) {vm.errors.address = [];}
                    vm.loadingProcess = false;
                    vm.addPatient();
                }
            });
        }
        }
    }

  }
}
</script>

<style scoped>
  .patient-gender-lable{
      padding: 0.3rem 3rem 0rem 0.5rem;
      border: none;
      border-radius: initial;
      position: initial;
      overflow: initial;
  }
</style>