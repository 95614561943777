<template>
  <div id="app">
    <LabIndex msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import LabIndex from './components/LabIndex.vue'

export default {
  name: 'App',
  components: {
    LabIndex
  }
}
</script>
