<template>
    <form class="multisteps_form" id="wizard" method="POST" action="thank-you.html">
        <div class="multisteps_form_panel" style="display: block;">
           <div class="step_content d-flex justify-content-between pt-5 pb-2">
              <h4>Payment Details</h4>
              <span class="text-end text-uppercase">Step 3 to 3</span>
           </div>
           <div class="step_progress_bar">
              <div class="progress rounded-pill">
                 <div class="progress-bar" style="width: 100%"></div>
              </div>
           </div>
           <div class="form_content">
              <div class="question_title py-5">
                 <h1 class="text-capitalize"> </h1>
              </div>
              <div class="alert alert-danger" v-if="errors.paymentDetails" role="alert">
                  {{errors.paymentDetails}}
              </div>
              <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 form_items">
                 <div class="col">
                    <h2>Panel Test Details</h2>
                    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 form_items">
                       <div class="col">
                          <span class="step_box_text pt-2">
                             Test Name
                          </span>
                       </div>
                       <div class="col"> <span class="step_box_text pt-2">
                             Price
                          </span> </div>
                    </div>
                    <div v-if="dataLoading">
                       <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 form_items">
                           <div class="col">
                               <p class="step_box_desc loading">
                                   L<i class="spinner-border spinner-border-sm"></i>ading
                               </p>
                           </div>
                           <div class="col">
                               <i class="spinner-grow spinner-grow-sm loading-text"></i>
                           </div>
                       </div>
                    </div>
                    <div v-for="labTest, index in labTestDetails.lab_test_details" :key="index">
                       <div v-for="test, index_a in labTest.lab_tests" :key="index_a" class="row row-cols-1 row-cols-sm-2 row-cols-md-2 form_items">
                           <div class="col">
                               <p class="step_box_desc">
                                   {{test.name}}
                               </p>
                           </div>
                           <div class="col">
                               ${{test.price}}
                           </div>
                       </div>
                    </div>
                    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 form_items">
                       <div class="col">
                          <p class="step_box_desc">
                             Total Amount
                          </p>
                       </div>
                       <div class="col">
                          ${{labTestDetails.total_amount}}
                       </div>
                    </div>
                 </div>
                 <div class="col">
                    <h2>Card Details</h2>
                    <div id="paymentResponse"></div>
                    <input type="hidden" name="payment_method_nonce" />

                    <label for="card-number" class="lab-braintree-lable">Card Number</label>
                    <div id="card-number"></div>
                    <div class="invalid-feedback  show-error">
                        Card number is required
                    </div>

                    <label for="cvv" class="lab-braintree-lable">CVV</label>
                    <div id="cvv"></div>
                    <div class="invalid-feedback show-error">
                        Security code required
                    </div>

                    <label for="expiration-date" class="lab-braintree-lable">Expiration Date</label>
                    <div id="expiration-date"></div>
                    <div class="invalid-feedback show-error">
                        Expiration date required
                    </div>
                 </div>
              </div>
           </div>
        </div>
        <div class="form_btn pt-5 d-flex justify-content-between">
           <button type="button" class="prev_btn text-uppercase bg-white" id="prevBtn" @click="nextPrev(-1)"><span><i class="fas fa-arrow-left"></i></span> Back </button>
           <button type="button" class="next_btn text-uppercase text-white" :disabled="loadingProcess" id="submitBtn" @click="submitPaymentForm()">{{nextButton}} <span><i :class="loadingProcess?'spinner-border spinner-border-sm':'fas fa-arrow-right'"></i></span></button>
        </div>
    </form>
</template>

<script>
const axios = require('axios');
import braintree from 'braintree-web';
export default {
  name: 'PaymentDetails',
  props: [
    'forwardData'
  ],
  created(){
        this.callBraintree();
        this.getLabTestDetails();
  },
  mounted(){
  },
  data() {
    return {
        errors: {
            paymentDetails: '',
        },
        labTestDetails: '',
        totalPaymentAmount: '',
        getLabTestToken: this.forwardData.lab_test.token ?? '',
        patientId:       this.forwardData.patient_id ?? '',
        braintreeToken:          '',
        hostedFieldsInstanceVar: '',
        cardNonce:               '',
        paymentId:               '',
        loadingProcess: false,
        nextButton:     'Pay & Save',
        dataLoading:    false,
    }
  },
  computed: {
  },
  methods: {
        nextPrev(n){
            this.$parent.currentStep(n);
        },

        getLabTestDetails(){
            let vm = this;
            vm.dataLoading = true;
            axios({method: 'POST', data: 'action=getLabTestDetails&getLabTestToken='+vm.getLabTestToken, url: window.location.origin+'/functions.php',}).then(function (response) {
                vm.labTestDetails = response.data.data;
                vm.totalPaymentAmount = vm.labTestDetails.total_amount;
                vm.dataLoading = false;
              });
        },

        callBraintree() {
            let vm = this;
            braintree.client.create({
              authorization: vm.$parent.braintreeToken
            }, function (clientErr, clientInstance) {
              if (clientErr) {
                document.getElementById("validateAddress").disabled = false;
                vm.showErrorMessage(clientErr.message);
                return;
              }

              braintree.hostedFields.create({
                client: clientInstance,
                styles: {
                  'input': {
                    'font-size': '14px'
                  },
                  'input.invalid': {
                    'color': 'red'
                  },
                  'input.valid': {
                    'color': 'green'
                  }
                },
                fields: {
                  number: {
                    container: '#card-number',
                    placeholder: '4111 1111 1111 1111',
                  },
                  cvv: {
                    container: '#cvv',
                    placeholder: '123'
                  },
                  expirationDate: {
                    container: '#expiration-date',
                    placeholder: 'MM / YYYY'
                  }
                },
              }, function (hostedFieldsErr, hostedFieldsInstance) {
                vm.hostedFieldsInstanceVar = hostedFieldsInstance;
                if (hostedFieldsErr) {
                    vm.showErrorMessage(hostedFieldsErr.message);
                    return;
                }

                hostedFieldsInstance.on('validityChange', function(event) {
                    vm.errors.paymentDetails = '';
                    var field = event.fields[event.emittedBy];
                    field.container.classList.remove('is-valid');
                    field.container.classList.remove('is-invalid');
                    field.container.nextSibling.classList.add('show-error');

                    if (field.isValid) {
                        field.container.classList.add('is-valid');
                    } else if (field.isPotentiallyValid) {
                        field.container.classList.add('is-invalid');
                    } else {
                        field.container.classList.add('is-invalid');
                    }
                });
              });
            });
        },
        submitPaymentForm() {
            var vm = this;
            vm.loadingProcess = true;
            vm.nextButton = 'Processing';

            var formIsInvalid = false;
            var state = this.hostedFieldsInstanceVar.getState();

            Object.keys(state.fields).forEach(function(field) {
                state.fields[field].container.classList.remove('is-invalid');
                state.fields[field].container.nextSibling.classList.add('show-error');

                if (state.fields[field].isEmpty) {
                    state.fields[field].container.classList.add('is-invalid');
                    state.fields[field].container.nextSibling.classList.remove('show-error');
                    formIsInvalid = true;
                } else if (!state.fields[field].isValid) {
                    let firstSibling = state.fields[field].container.previousSibling;
                    let lastSibling = state.fields[field].container.nextSibling;
                    if (firstSibling.textContent == 'Card Number') {
                        lastSibling.textContent ='Credit card number is invalid';
                        lastSibling.classList.remove('show-error');
                    } else if (firstSibling.textContent == 'Expiration Date') {
                        lastSibling.textContent ='Expiry date is invalid';
                        lastSibling.classList.remove('show-error');
                    } else if (firstSibling.textContent == 'CVV') {
                        lastSibling.textContent ='CVV is invalid';
                        lastSibling.classList.remove('show-error');
                    }

                    state.fields[field].container.classList.add('is-invalid');
                    formIsInvalid = true;
                }
            });

            if (formIsInvalid) {
                vm.loadingProcess = false;
                vm.nextButton = 'Pay & save';
              return;
            }

            vm.hostedFieldsInstanceVar.tokenize(function (tokenizeErr, payload) {
              if (tokenizeErr) {
                // removeLoading();
                this.showErrorMessage('Something went wrong. Check your card details and try again.');
                vm.loadingProcess = false;
                vm.nextButton = 'Pay & save';
                return;
              }

              vm.cardNonce = payload.nonce;
              vm.loadingProcess = false;
              vm.nextButton = 'Pay & save';
              vm.initiateBraintreePayment();
            });
        },
        showErrorMessage(message) {
            if (message) {
                this.errors.paymentDetails = message;
                return false;
            } else {
                return true;
            }
        },

        initiateBraintreePayment() {
            let vm = this;
            vm.loadingProcess = true;
            vm.nextButton = 'Processing Payment';
            axios({method: 'POST', data: 'action=initiateBraintreePayment&patient_id='+vm.patientId+'&payment_amount='+vm.totalPaymentAmount+'&card_nonce='+vm.cardNonce, url: window.location.origin+'/functions.php',}).then(function (response) {
                if (response.data.status == 'error') {
                    vm.errors.paymentDetails = response.data.message;
                    vm.loadingProcess = false;
                    vm.nextButton = 'Pay & save';
                }else {
                    vm.paymentId = response.data.payment_id;
                    vm.updateOrderPaymentDetails();
                }
            });
        },
        updateOrderPaymentDetails(){
            let vm = this;
            vm.loadingProcess = true;
            vm.nextButton = 'Saveing details';
            axios({method: 'POST',
            data: 'action=updateOrderPaymentDetails&patient_id='+vm.patientId+'&payment_amount='+vm.totalPaymentAmount+'&getLabTestToken='+vm.getLabTestToken+'&payment_id='+vm.paymentId,
            url: window.location.origin+'/functions.php',}).then(function (response) {
                if (response.data.status == 'success') {
                    vm.nextPrev(1);
                }else {
                    vm.errors.paymentDetails = 'Something went wrong';
                    vm.loadingProcess = false;
                    vm.nextButton = 'Pay & save';
                }
            });
        }


  }
}
</script>

<style scoped>
    .lab-braintree-lable{
        padding: 0rem;
        border: none;
        border-radius: initial;
        position: initial;
        overflow: initial;
    }

    div#card-number {
        height: 40px;
        border: 1px solid #CCC;
        padding-left: 5px;
    }

    div#expiration-date {
        height: 40px;
        border: 1px solid #ccc;
        padding-left: 5px;
    }

    div#cvv {
        height: 40px;
        border: 1px solid #ccc;
        padding-left: 5px;
    }

    .form-group.is-invalid {
        border: 1px solid #f63a03 !important;
    }
    .invalid-feedback {
        color: #f63a03;
        font-weight: bold;
    }
    .show-error {
        display: none;
    }
    .bootstrap-tagsinput {
        width: 100%;
    }
    .is-invalid {
    border: 1px solid #f63a03 !important;
    }
    .show-error {
        display: none;
    }
    .invalid-feedback {
        color: #f63a03;
        font-weight: bold;
    }

</style>