<template>
    <form class="multisteps_form" id="wizard">
        <div class="multisteps_form_panel" style="display: block;">
           <div class="step_content d-flex justify-content-between pt-5 pb-2">
              <h4>LAB TEST</h4>
              <span class="text-end text-uppercase">Step 2 to 3</span>
           </div>
           <div class="step_progress_bar">
              <div class="progress rounded-pill">
                 <div class="progress-bar" style="width:50%"></div>
              </div>
           </div>
           <div class="form_content">
              <div class="question_title py-5">
                 <h1 class="text-capitalize"> </h1>
              </div>

              <div class="alert alert-danger" v-if="errors.formSubmition" role="alert">
                  {{errors.formSubmition}}
              </div>
              <div class="accordion" id="accordionExample">
                  <div v-if="dataLoading" class="accordion-item">
                     <h2 class="accordion-header" id="">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        </button>
                     </h2>
                     <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                           <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 form_items">
                              <h3 class="loading-text loading">L<i class="spinner-border"></i>ading</h3>
                           </div>
                        </div>
                     </div>
                  </div>
                 <div v-for="lab, index in labs" :key="index"  class="accordion-item">
                    <h2 class="accordion-header" :id="'heading'+index">
                       <button class="accordion-button" :class="index==0 ? '' :'collapsed'" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse'+index" :aria-expanded="index==0 ? 'true':'false'" :aria-controls="'collapse'+index">
                          {{lab.name}}
                       </button>
                    </h2>
                    <div :id="'collapse'+index" class="accordion-collapse collapse" :class="index==0 ? 'show' :''" :aria-labelledby="'heading'+index" data-bs-parent="#accordionExample">
                       <div class="accordion-body">

                          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 form_items">
                             <div v-for="lab_test, index_a in lab.lab_test" :key="index_a" class="col-md-6 mb-3">
                                <input v-model="formData.order" :value="{'lab_id':lab_test.lab_id,'test_id':lab_test.test_id}"
                                type="checkbox" data-lab="1" :id="'lab'+lab.id+'test'+lab_test.id" :class="'lab-test-checkbox'+lab_test.id" :disabled="loadingProcess">
                                <label class="lab-test-lable" :for="'lab'+lab.id+'test'+lab_test.id">{{lab_test.test.name}}</label>
                                <span class="test-price">{{lab_test.price}}</span>
                                <p style="margin: 10px 0 0 5px;">{{lab_test.test_description}}</p>
                             </div>
                          </div>

                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </div>
        <div class="form_btn pt-5 d-flex justify-content-between">
           <button type="button" class="prev_btn text-uppercase bg-white" id="prevBtn" @click="nextPrev(-1)"><span><i class="fas fa-arrow-left"></i></span> Back </button>
           <button type="button" class="next_btn text-uppercase text-white" :disabled="loadingProcess" @click="saveFormData()" id="nextBtn">{{nextButton}} <span><i :class="loadingProcess?'spinner-border spinner-border-sm':'fas fa-arrow-right'"></i></span></button>
        </div>
    </form>
</template>

<script>
const axios = require('axios');
export default {
  name: 'LabTest',
  props: [
      'forwardData'
  ],
  created(){
      this.getLabs();

      let lab_test = JSON.parse(localStorage.getItem("lab_test"));
      if (lab_test) {
          this.formData = lab_test.form_data;
          this.patientId = lab_test.patient_id;
      }
  },
  mounted(){
  },
  data() {
      return {
          labs: '',
          formData: {
              order: [],
          },
          errors: {
              formSubmition:'',
          },
          patientId: this.forwardData.patient_id ?? '',
          loadingProcess: false,
          nextButton:     'Next',
          dataLoading:    false,
      }
  },
  computed: {
  },
  methods: {
      nextPrev(n,forwardData){
          this.$parent.currentStep(n,forwardData);
          if (n == 1) {
              localStorage.setItem("lab_test", JSON.stringify({'form_data':this.formData, 'patient_id':this.patientId}));
          }
      },

      getLabs() {
            let vm = this;
            vm.dataLoading = true;
            axios({method: 'POST', data: 'action=getLabData', url: window.location.origin+'/functions.php',}).then(function (response) {
                vm.labs = response.data.data;
                vm.dataLoading = false;
              });
      },

      saveFormData(){
          let vm = this;
          vm.errors.formSubmition = false;
          if (vm.formData.order.length <= 0) {
              vm.errors.formSubmition = 'Please select tests';
              return;
          }
          vm.loadingProcess = true;
          vm.nextButton = 'Processing';
          let labOrder = JSON.stringify(vm.formData.order);
          axios({method: 'POST', data: 'action=storeLabTest&order='+labOrder+'&patientId='+vm.patientId, url: window.location.origin+'/functions.php',}).then(function (response) {
               if (response.data.status == 'success') {
                 vm.nextPrev(1,{'lab_test':response.data.data, 'patient_id':vm.patientId});
                 vm.loadingProcess = false;
                 vm.nextButton = 'Next';
             }else {
                 vm.errors.formSubmition = 'Something went wrong';
                 vm.loadingProcess = false;
                 vm.nextButton = 'Next';
             }
            });
      }
  }
}
</script>

<style scoped>
  .lab-test-lable{
      padding: 0rem 0rem 0rem 1rem;
      border: none;
      border-radius: initial;
      position: initial;
      overflow: initial;
  }
</style>