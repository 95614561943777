<template>
    <div id="wrapper">
        <div class="container">
            <div class="row text-center">
                <div class="shap_content position-relative">
                    <img class="w-50" src="../assets/images/rectangle.png" alt="image_not_found">
                    <div class="thankyou-title">
                        <h1 class="position-absolute">Thankyou For your Participation!</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ThankYou',
  props: {
  msg: String
  }
}
</script>