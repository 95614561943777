<template>
    <div v-if="LabOrderTab" class="wrapper position-relative overflow-hidden">
       <div class="container-md-fluid p-3 p-lg-0 me-5">
           <div class="row">

               <div class="col-xl-4">
                  <div class="form_logo position-absolute">
                     <a href="">
                        <img src="../assets/images/logo/logo.png" alt="image-not-found">
                     </a>
                  </div>
                  <div class="steps_area step_area_fixed d-none d-xl-block">
                     <div class="image_holder">
                        <img class="overflow-hidden" src="../assets/images/background/bg_0.png" alt="image-not-found">
                     </div>
                     <div class="step_items position-absolute">
                        <div class="step d-block text-center bg-white position-relative current active">1</div>
                        <div class="step d-block text-center bg-white position-relative" :class="{ 'active': step >= 2 }">2
                        </div>
                        <div class="step d-block text-center bg-white position-relative last" :class="{ 'active': step >= 3 }">3
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xl-7 ps-5 pt-5">
                      <LabTest v-if="step == 2" :forwardData="forwardDataNext" />
                      <PatientDetails v-if="step == 1" :forwardData="forwardDataNext"/>
                      <PaymentDetails v-if="step == 3" :forwardData="forwardDataNext"/>
               </div>

           </div>
       </div>
    </div>
    <ThankYou v-else-if="thankYouTab" msg="ThankYou"/>
</template>

<script>
import LabTest from './tabs/LabTest.vue'
import PatientDetails from './tabs/PatientDetails.vue'
import PaymentDetails from './tabs/PaymentDetails.vue'
import ThankYou from './ThankYou.vue'
const axios = require('axios');

export default {
  name: 'LabIndex',
  components: {
      LabTest,
      PatientDetails,
      PaymentDetails,
      ThankYou
  },
  props: {
      msg: String
  },
  created(){
      localStorage.removeItem('patient_details');
      localStorage.removeItem('lab_test');
      this.getBraintreeToken();
  },
  mounted(){
  },
  data() {
      return {
          step: 1,
          LabOrderTab: true,
          thankYouTab: false,
          forwardDataNext: '',
          braintreeToken:  '',
      }
  },
  computed: {
  },
  methods: {
      currentStep(n,data){
          this.forwardDataNext = data ?? '';
          this.step = this.step+n;
          if (this.step == 4) {
              this.LabOrderTab = false;
              this.thankYouTab = true;
          }
      },

      getBraintreeToken() {
          let vm = this;
          axios({method: 'POST', data: 'action=getBraintreeToken', url: window.location.origin+'/functions.php',}).then(function (response) {
              var result = response.data;
              if (result.code == 0) {
                  vm.braintreeToken = result.token;
              }
          });
      },
  }
}
</script>